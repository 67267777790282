import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { IJogo } from "../Interfaces/Jogo";
import { IAposta } from "../Interfaces/Aposta";
import { IConcurso } from "../Interfaces/Concurso";

interface Props {
    Jogo: IJogo;
    Apostas: IAposta[];
    Concursos: IConcurso[];
    MaxDigits: number;
    formatter: any;
    url: string;
}

const PDFJogoResultado = ({Jogo, Apostas, Concursos, MaxDigits, formatter, url} : Props) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const maiorConcurso = Concursos.reduce((maxIndex, concurso, index, arr) => {
        return (concurso.LANCAMENTO?.length || 0) > (arr[maxIndex].LANCAMENTO?.length || 0) ? index : maxIndex;
    }, 0);
    const maxLancamentosConcurso = Math.max(...Concursos.map(c => c.LANCAMENTO?.length || 0));

    const title     : Content = [
        {
            text: [
                {text: `${'Dezeninha'}\n`, fontSize: 15, bold: true}
            ],            
            alignment: 'center',
            margin: [15, 20, 15, 0],
        }
    ];

    const qrCode : Content = [
        {
            columns: [
                {
                    qr: `${url}/jogo/detalhe/${Jogo.CD_JOGO}/resultado`,
                    fit: 90,
                    alignment: 'left',
                    margin: [30, 0, 5, 0],
                    width: 100
                },
                {
                    text: [
                        {text: `${Jogo.NOME}\n`, fontSize: 12, color: 'blue', decoration: 'underline', link: `${url}/jogo/detalhe/${Jogo.CD_JOGO}/resultado`, bold: true},
                        {text: `Acompanhe suas apostas sempre atualizadas através do QrCode!`, fontSize: 10},
                        {text: `\n\nPosição dos resultados em: ${formatter.setDate(new Date(), {dateStyle: "medium"}, false)}`, fontSize: 10}
                    ],
                    alignment: 'left',
                    margin: [50, 0, 0, 0],
                    width: 'auto'
                }
            ],
        }
        
    ]

    const footer    : Content = [
        {
            text: `${Jogo.OBSERVACAO}`,
            alignment: 'center',
            fontSize: 8
        }
    ]

    const dadosConcursos  : Content = [
        {
            margin: [10, 10, 10, 0],
            layout: 'headerLineOnly',
            table: {
                headerRows: 1,
                widths: ['*', ...Concursos[maiorConcurso].LANCAMENTO!.map(x => 16), 120],
                body: [
                    [
                        {text: `Concurso`, style: 'tableHeader', fontSize: 10},
                        ...Concursos[maiorConcurso].LANCAMENTO!.map((x, n) => { return {text: `Nº${n+1}`, style: 'tableHeader', fontSize: 10} }),
                        {text: `Data`, style: 'tableHeader', fontSize: 10}
                    ],
                    ...Concursos.map((concurso: IConcurso) => [
                        {text: concurso.NM_CONCURSO, fontSize: 8}, // Nome do concurso
                        ...Array.from({ length: maxLancamentosConcurso }, (_, i) => {
                            const lancamento = concurso.LANCAMENTO?.[i];
                            return {
                                text: lancamento ? lancamento.toString().padStart(MaxDigits, '0') : '-',
                                fontSize: 8,
                                alignment: 'center',
                            };
                        }),
                        {text: `${formatter.setDate(new Date(concurso.DT_CONCURSO!), {dateStyle: "medium"}, false)}`, fontSize: 8} // data do concurso
                    ])
                ]
            }
        }
    ];

    const dadosJogos  : Content = [
        {
            margin: [10, 30, 10, 0],
            layout: 'headerLineOnly',
            table: {
                headerRows: 1,
                widths: ['*', ...Apostas[0].LANCAMENTO!.map(x => 16), 34],
                body: [
                    [
                        {text: `Nome`, style: 'tableHeader', fontSize: 10},
                        ...Apostas[0].LANCAMENTO!.map((x, n) => { return {text: `Nº${n+1}`, style: 'tableHeader', fontSize: 10} }),
                        {text: `Pontos`, style: 'tableHeader', fontSize: 10},
                    ],
                    ...Apostas.map((aposta: IAposta) => [
                        {text: aposta.NOME, fontSize: 8}, // Nome da aposta
                        ...aposta.LANCAMENTO!.map(lancamento => {
                            return {
                                text: lancamento.NUMERO.toString().padStart(MaxDigits, '0'), 
                                fontSize: 8, 
                                colSpan: 1, 
                                rowSpan: 1, 
                                alignment: 'center',
                                background: lancamento.ACERTO ? '#42e6f5' : 'white'
                            };
                        }),
                        {text: aposta.PONTO, fontSize: 8}
                    ])
                ]
            }
        }
    ];

    const semDados : Content = [
        {
            text: ``,
            alignment: 'center',
            fontSize: 8
        }
    ];

    // const details   : Content = [
    //     {
    //         table: {
    //             headerRows: 2,
    //             widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
    //             body: [
    //                 [ 
    //                     {text: 'Jogador', style: 'tableHeader', fontSize: 8, colSpan: 10, bold: true},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                 ],
    //                 [ 
    //                     {text: 'Nº 1', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 2', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 3', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 4', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 5', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 6', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 7', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 8', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 9', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 10', style: 'tableHeader', fontSize: 8, bold: true}
    //                 ],
    //                 ...dadosJogos
    //             ]
    //         },
    //         layout: { fillColor: function (rowIndex, node, columnIndex) {
    //             return (rowIndex % 2 === 1 && rowIndex > 0) ? '#CCCCCC' : null;
    //         }}
    //     },
    // ];

    // const footer = (currentPage: number , pageCount: number) : Content => {
    //     return [
    //         {
    //             text: currentPage.toString() + ' / ' + pageCount.toString(),
    //             alignment: 'center',
    //             fontSize: 9,
    //             margin: [0, 10, 20, 0],
    //         }
    //     ]
    // }

    const docDefinitions : TDocumentDefinitions = {
        pageSize: 'A4',
        pageMargins: [20, 50 , 20 , 40], //left top right bottom

        header: [title],
        content: [
            qrCode, 
            dadosConcursos ?? semDados, 
            dadosJogos ?? semDados
        ],
        footer: [footer]
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default PDFJogoResultado;