import { useOutletContext } from "react-router-dom";
import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { Formik } from "formik";
import { useEffect, useLayoutEffect, useState } from "react";
import { EMode } from "../../Interfaces";
import BadgeValidacao from "../../Components/BadgeValidacao";
import { every } from "lodash";
import { EConcursoStatus, IConcurso, schemaConcurso } from "../../Interfaces/Concurso";
import { IUseConcurso } from "./Hook/useConcurso";
import useTipoJogoController from "../../Controllers/TipoJogo";
import useJogoController from "../../Controllers/Jogo";
import { ConcursoStatus } from "../../Constants/concurso";
import Tabela from "../../Components/tabela";
import { EStatus, IJogo } from "../../Interfaces/Jogo";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import CartaoDezena from "../../Components/aposta/cartaoDezena";
import { ITipoJogo } from "../../Interfaces/TipoJogo";

const ConcursoCadastro = () => {
    const Concurso = useOutletContext<IUseConcurso>();
    const { Listar: ListaTipoJogo } = useTipoJogoController({updateQueryKey: 'TipoJogo', enableQuery: true});
    const { Listar: ListaJogo } = useJogoController({updateQueryKey: 'Jogo', enableQuery: true});
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [Jogos, setJogos] = useState<IJogo[]>([]);
    const [selecionadoJogo, setSelecionadoJogo] = useState<number>();
    const [selecionado, setSelecionado] = useState<Array<number>>([]);
    const [selecionadoTipoJogo, setSelecionadoTipoJogo] = useState<ITipoJogo>();
    const [carregamento, setCarregamento] = useState<boolean>(true);

    useEffect(() => {
        setJogos(Concurso.Datasource.Item.JOGO && Concurso.Datasource.Item.JOGO.length > 0 ? Concurso.Datasource.Item.JOGO?.map(jogo => {
            return {
                CD_JOGO: jogo,
                CD_TIPO_JOGO: Concurso.Datasource.Item.CD_TIPO_JOGO,
                NOME: ListaJogo.data?.find(lista => Number(lista.CD_JOGO) === jogo)?.NOME ?? "Jogo não encontrado"
            } as IJogo
        }) : [])
    }, [Concurso.Datasource.Item, ListaJogo.data]);

    useEffect(() => {
        setSelecionado(Concurso.Datasource.Item.LANCAMENTO && Concurso.Datasource.Item.LANCAMENTO.length > 0 ? Concurso.Datasource.Item.LANCAMENTO : [])
    }, [Concurso.Datasource.Item]);

    useLayoutEffect(() => {
        if ((ListaTipoJogo.isFetched && ListaTipoJogo.isSuccess && ListaTipoJogo.data) 
            && ((Concurso.Modo === EMode.Edit && (Concurso.Controller.Buscar.isSuccess && Concurso.Controller.Buscar.data)) 
                || Concurso.Modo === EMode.Include)
        ) {
            setCarregamento(false);
        } else if (ListaTipoJogo.isError) {
            throw 'Erro ao carregar TipoJogo: ' + ListaTipoJogo.error // eslint-disable-line
        }
    }, [ListaTipoJogo.data, ListaTipoJogo.isFetched, ListaTipoJogo.isSuccess, ListaTipoJogo.isError, ListaTipoJogo.error, Concurso.Controller.Buscar.isSuccess, Concurso.Controller.Buscar.data, Concurso.Modo]);

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaConcurso}
                onSubmit={(dados : IConcurso) => {
                    if (Concurso.Modo === EMode.Edit) {
                        Concurso.Controller.Alterar.mutateAsync(dados, {
                            onSuccess: () => {
                                Concurso.Navegar('0');
                            }
                        });
                    } else if (Concurso.Modo === EMode.Include) {
                        const objeto = Object.assign({}, dados);
                        if (Jogos && Jogos.length > 0) { // transformar Jogos em array com numeros
                            objeto.JOGO = Jogos.map(jogo => jogo.CD_JOGO) as number[];
                        } else {
                            alert('Nenhum jogo foi selecionado para o concurso. É necessário informar pelo menos um.');
                            return;
                        }
                        
                        if (selecionado && selecionadoTipoJogo && (
                                (selecionado.length < selecionadoTipoJogo.QTD_MAX_CONCURSO) ||
                                (selecionado.length > selecionadoTipoJogo.QTD_MIN_CONCURSO)
                            )) { // validar numeros selecionados para o concurso
                            objeto.LANCAMENTO = [...selecionado].sort((a, b) => a - b);
                        } else {
                            alert('Quantidade de números selecionados para o concurso não corresponde ao tipo de jogo selecionado.');
                            return;
                        }
                        
                        Concurso.Controller.Cadastrar.mutateAsync(objeto, {
                            onSuccess: () => {
                                Concurso.Navegar('0');
                            }
                        });
                    }
                }}
                initialValues={{
                    CD_TIPO_JOGO    : Concurso.Datasource.Item.CD_TIPO_JOGO ?? undefined,
                    NM_CONCURSO     : Concurso.Datasource.Item.NM_CONCURSO  ?? "",
                    DT_CONCURSO     : Concurso.Datasource.Item.DT_CONCURSO  ?? "",
                    DT_CADASTRO     : Concurso.Datasource.Item.DT_CADASTRO  ?? "",
                    LANCAMENTO      : Concurso.Datasource.Item.LANCAMENTO   ?? [],
                    JOGO            : Concurso.Datasource.Item.JOGO         ?? [],
                    STATUS          : Concurso.Datasource.Item.STATUS       ?? "RA",
                    PARCIAL         : Concurso.Datasource.Item.PARCIAL      ?? ""
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => Concurso.Navegar('0')}>
                        <Row>
                            <Form.Group className="mt-3">
                                <Form.Label>Identificação do Concurso:</Form.Label>
                                <BadgeValidacao validacao={errors.NM_CONCURSO} confirmado={confirmado} />
                                <Form.Control 
                                    name={'NM_CONCURSO'}
                                    type={'text'}
                                    value={values.NM_CONCURSO ?? ""}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NM_CONCURSO, confirmado])}
                                    placeholder="Nome ou número identificador do concurso..." 
                                    size="lg"
                                />
                            </Form.Group> 
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Tipo Jogo:</Form.Label>
                                    <BadgeValidacao validacao={errors.CD_TIPO_JOGO} confirmado={confirmado} />
                                        <Form.Select 
                                            disabled={Concurso.Modo === EMode.Edit}
                                            name={'CD_TIPO_JOGO'}
                                            value={values.CD_TIPO_JOGO ?? ""}
                                            onChange={ e => {
                                                if ((Jogos && Jogos.length > 0) && (Number(e.target.value) !== Number(values.CD_TIPO_JOGO))) {
                                                    setJogos([]);
                                                    setSelecionadoJogo(undefined);                                                    
                                                };

                                                setFieldValue("CD_TIPO_JOGO", Number(e.target.value));
                                                setSelecionadoTipoJogo(ListaTipoJogo.data?.find(tipojogo => Number(tipojogo.CD_TIPO_JOGO) === Number(e.target.value)));
                                            }}
                                            isInvalid={every([errors.CD_TIPO_JOGO, confirmado])}
                                            size="lg"
                                        >
                                            <option key={''} value={''} disabled={true}>Selecione</option>
                                            {ListaTipoJogo.isFetched && ListaTipoJogo.isSuccess && ListaTipoJogo.data && ListaTipoJogo.data.map((TipoJogo) => (
                                                <option key={TipoJogo.CD_TIPO_JOGO} value={TipoJogo.CD_TIPO_JOGO}>
                                                    {TipoJogo.NOME}
                                                </option>
                                            ))}
                                        </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Data do Concurso:</Form.Label>
                                    <BadgeValidacao validacao={errors.DT_CONCURSO} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'DT_CONCURSO'}
                                        type="datetime"
                                        value={values.DT_CONCURSO?.toString()}
                                        onChange={handleChange}
                                        isInvalid={every([errors.DT_CONCURSO, confirmado])}
                                        size="lg"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Parciais:</Form.Label>
                                    <Form.Control 
                                        name={'PARCIAL'}
                                        value={values.PARCIAL}
                                        onChange={handleChange}
                                        size="lg"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row style={Concurso.Modo === EMode.Include ? {display: 'none'} : {}}>
                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Status:</Form.Label>
                                    <BadgeValidacao validacao={errors.STATUS} confirmado={confirmado} />
                                    <Form.Select 
                                        name={'STATUS'}
                                        value={values.STATUS ?? "RA"}
                                        onChange={ e => {
                                            setFieldValue("STATUS", e.target.value);
                                        }}
                                        isInvalid={every([errors.STATUS, confirmado])}
                                        disabled
                                        size="lg"
                                    >
                                        {ConcursoStatus.map((Item) => (
                                            <option key={Item.STATUS} value={Item.STATUS} disabled={values.STATUS === EConcursoStatus.Finalizado}>
                                                {Item.DESCRICAO}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Data de Cadastro:</Form.Label>
                                    <BadgeValidacao validacao={errors.DT_CADASTRO} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'DT_CADASTRO'}
                                        type="datetime-local"
                                        value={values.DT_CADASTRO?.toString()}
                                        onChange={handleChange}
                                        isInvalid={every([errors.DT_CADASTRO, confirmado])}
                                        disabled
                                        size="lg"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <div className="mt-3">
                            <Card>
                                <Card.Header>
                                    Jogos que serão pontuados pelo concurso
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group style={Concurso.Modo === EMode.Edit ? {display: 'none'} : {}}>
                                        <InputGroup>
                                            <Form.Select 
                                                name={'CD_JOGO_SELECAO'}
                                                value={selecionadoJogo ?? ''}
                                                disabled={!values.CD_TIPO_JOGO}
                                                onChange={ e => {
                                                    setSelecionadoJogo(Number(e.target.value));
                                                }}
                                                size="lg"
                                            >
                                                <option key={''} value={''} disabled={true}>Selecione</option>
                                                {
                                                    ListaJogo.isFetched && ListaJogo.isSuccess && ListaJogo.data && ListaJogo.data.filter(jogo => 
                                                        !Jogos.some(j => j.CD_JOGO === jogo.CD_JOGO) &&                 // Filtra os jogos já selecionados
                                                        Number(jogo.CD_TIPO_JOGO) === Number(values.CD_TIPO_JOGO) &&    // Filtra os jogos pelo mesmo CD_TIPO_JOGO
                                                        jogo.STATUS === EStatus.Andamento                               // Filtra somente jogos em andamento
                                                    ).map((jogo) => 
                                                        <option key={jogo.CD_JOGO} value={jogo.CD_JOGO}>
                                                            {jogo.NOME}
                                                        </option>
                                                    )
                                                }
                                            </Form.Select>
                                            <Button
                                                disabled={!(selecionadoJogo && selecionadoJogo > 0)}
                                                onClick={() => {
                                                    if (selecionadoJogo && selecionadoJogo > 0) {
                                                        setJogos(prev => [...prev, ListaJogo.data?.find(jogo => jogo.CD_JOGO === selecionadoJogo) as IJogo]);
                                                        setSelecionadoJogo(undefined);
                                                    }
                                                }}
                                            >
                                                <FaPlus />
                                            </Button>
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="mt-3">
                                        <Tabela 
                                            dados={Jogos} 
                                            colunas={
                                                Concurso.Modo === EMode.Edit ? // Edicao
                                                    [
                                                        {
                                                            Header: 'Código',
                                                            id: "CD_JOGO",
                                                            accessor: "CD_JOGO"
                                                        },
                                                        {
                                                            Header: 'Jogo',
                                                            id: "nome",
                                                            accessor: "NOME"
                                                        },
                                                    ]
                                                : // Cadastro
                                                    [
                                                        {
                                                            Header: 'Código',
                                                            id: "CD_JOGO",
                                                            accessor: "CD_JOGO"
                                                        },
                                                        {
                                                            Header: 'Jogo',
                                                            id: "nome",
                                                            accessor: "NOME"
                                                        },
                                                        {
                                                            id: "BtnExcluir",
                                                            Header: "",
                                                            Cell: (row: any) : any => 
                                                                <div className="w-100 d-flex justify-content-around">
                                                                    <Button 
                                                                        variant="danger"
                                                                        onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            const selecionado : IJogo = row.row.original;
                                                                            if (window.confirm(`Deseja remover o Jogo: ${selecionado.NOME}?`)) {
                                                                                const excluirJogo = Jogos.filter(jogo => !(jogo.CD_JOGO === selecionado.CD_JOGO));
                                                                                setJogos(excluirJogo);
                                                                                // setFieldValue("COMISSAO", excluirComissao);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FaTrashAlt />
                                                                    </Button>
                                                                </div>
                                                        },
                                                    ]
                                            }
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                        <Row>
                            {
                                carregamento ?
                                    <div className="d-flex w-100 justify-content-center mt-3 align-items-center">
                                        <Spinner variant="primary" />
                                    </div>
                                :
                                    !!values.CD_TIPO_JOGO && <div className="my-3">
                                        <Card>
                                            <Card.Header>
                                                Lançamento de números do concurso
                                            </Card.Header>
                                            <Card.Body>
                                                <CartaoDezena 
                                                    VlrMin={ListaTipoJogo.data?.find(TipoJogo => Number(TipoJogo.CD_TIPO_JOGO) === Number(values.CD_TIPO_JOGO))!.VLR_MIN ?? 0} 
                                                    VlrMax={ListaTipoJogo.data?.find(TipoJogo => Number(TipoJogo.CD_TIPO_JOGO) === Number(values.CD_TIPO_JOGO))!.VLR_MAX ?? 0}
                                                    QtdMin={ListaTipoJogo.data?.find(TipoJogo => Number(TipoJogo.CD_TIPO_JOGO) === Number(values.CD_TIPO_JOGO))!.QTD_MIN_CONCURSO ?? 0} 
                                                    QtdMax={ListaTipoJogo.data?.find(TipoJogo => Number(TipoJogo.CD_TIPO_JOGO) === Number(values.CD_TIPO_JOGO))!.QTD_MAX_CONCURSO ?? 0} 
                                                    Selecionado={selecionado}
                                                    setSelecionado={setSelecionado}
                                                    Carrinho={undefined}
                                                    setCarrinho={undefined}        
                                                    Modo={Concurso.Modo === EMode.Include ? 'Edit' : 'Browse'}                    
                                                />
                                            </Card.Body>
                                        </Card>
                                    </div>
                            }
                        </Row>

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" onClick={() => {}} type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" onClick={() => {setConfirmado(true)}} type="submit">Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>            
        </Container>
    )
}

export default ConcursoCadastro;