import { useEffect, useLayoutEffect, useMemo } from "react";
import usePagina from "../../../../Components/usePagina";
import useConcursoController, { IUseConcursoController } from "../../../../Controllers/Concurso"
import useConcursoDatasource, { IUseConcursoDatasource } from "./datasource";
import useConcursoGrid, { IUseConcursoGrid } from "./grid";
import { EMode } from "../../../../Interfaces";

interface Props {
    CodigoJogo?: string;
    CodigoConcurso?: string;
}

const useConcurso = ({CodigoConcurso, CodigoJogo} : Props) => {
    const {NavegaPagina} = usePagina();
    const Controller = useConcursoController({
        updateQueryKey: "Concurso",
        enableQuery: !CodigoJogo,
        enableUsuario: !!CodigoConcurso,
        queryValue: CodigoJogo
    });
    const Datasource = useConcursoDatasource({Controller});
    const Grid = useConcursoGrid({Controller: Controller});
    const Codigo = CodigoConcurso;

    useLayoutEffect(() => {
        if (Codigo && Number(Codigo) > 0) {
            if (Controller.Buscar.isSuccess && (Controller.Buscar.data?.CD_CONCURSO ?? 0) !== Number(Codigo)) {
                Controller.Buscar.mutate({CD_CONCURSO: Number(Codigo)});
            }
        } else {
            // Controller.Buscar.reset(); Acredito que não há necessidade de limpar tudo
        }
    }, [Codigo]); //eslint-disable-line

    useEffect(() => {
        if (CodigoJogo && CodigoJogo !== '0') {
            Controller.ListarJogo.refetch();
        };
    }, [CodigoJogo]); // eslint-disable-line

    const Modo : EMode = useMemo(() => {
        switch (Codigo) {
            case '0':
                return EMode.Browse;
            case '-1':
                return EMode.Include;
            default:
                return EMode.Edit;
        }
    }, [Codigo]);

    const Navegar = (CodigoConcurso: string) => {
        if (CodigoConcurso === '0' || CodigoConcurso === undefined) {
            NavegaPagina('Concurso', [], []);
        } else {
            NavegaPagina('ConcursoCadastro', ['cdConcurso'], [CodigoConcurso]);
        }
    }

    return {
        Controller,
        Datasource,
        Navegar,
        Grid,
        Modo,
        Codigo
    }
}

export interface IUseConcurso {
    Controller  : IUseConcursoController;
    Datasource  : IUseConcursoDatasource;
    Navegar     : (CodigoConcurso: string) => void;
    Grid        : IUseConcursoGrid;
    Modo        : EMode;
    Codigo      : string | undefined;
}

export default useConcurso;