import { Badge, Button, Card } from "react-bootstrap";
import { IUseColorScheme } from "../aposta/colorScheme";
import { EPuleStatus, IPule } from "../../Interfaces/Pule";
import TabelaMais from "../tabela/tabelaMais";
import CartaoPuleAposta from "./cartaoPuleAposta";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EStatus, IJogo } from "../../Interfaces/Jogo";
import { IAposta } from "../../Interfaces/Aposta";
import { FaReceipt, FaRegCopy } from "react-icons/fa";
import CartaoPuleConfirmacao from "./cartaoPuleConfirmacao";
import PDFComprovantePule from "../../Reports/comprovantePule";
import { FaArrowRotateRight } from "react-icons/fa6";
import { toast } from "react-toastify";

interface Props extends React.HTMLProps<HTMLDivElement> {
    Colors: IUseColorScheme;
    MaxDigits: number;
    Pule: IPule;
    Jogo: IJogo;
    formatter: any;

    ModoExibicao?: 'ListaJogo' | 'ListaAposta' | 'Confirmacao' | 'ListaPremio';
    RepeteAposta?: IRepeteAposta;
    Gerencial?: boolean;
}

interface IRepeteAposta {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selecionada: Array<number[]>;
    setSelecionada: React.Dispatch<React.SetStateAction<Array<number[]>>>;
    selecionadaTipoJogo: number | undefined;
    setSelecionadaTipoJogo: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const BUFFER_SIZE = 50;

function transformarApostasEmNumeros(apostas: IAposta[]): number[][] {
    return apostas.map(aposta => 
        aposta.LANCAMENTO?.map(lancamento => lancamento.NUMERO) || []
    );
}

const DynamicList = ({ items, children }: any) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [visibleItems, setVisibleItems] = useState<any[]>([]);
    const [viewport, setViewport] = useState({ start: 0, end: BUFFER_SIZE });

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
            const itemHeight = scrollHeight / items.length; // Altura média de cada item

            // Determina os novos índices de início e fim baseado na posição de rolagem
            const newStart = Math.max(0, Math.floor(scrollTop / itemHeight) - (BUFFER_SIZE * 10));
            const newEnd = Math.min(items.length, Math.ceil((scrollTop + clientHeight) / itemHeight) + (BUFFER_SIZE * 10));

            // Só atualiza se o novo viewport for diferente do atual
            if (newStart !== viewport.start || newEnd !== viewport.end) {
                setViewport({ start: newStart, end: newEnd });
            }
        }
    }, [items.length, viewport.start, viewport.end]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const newVisibleItems = items.slice(viewport.start, viewport.end);
        setVisibleItems(newVisibleItems);
    }, [viewport, items]);

    return (
        <div ref={containerRef} style={{ overflowY: 'scroll', height: '20rem', paddingRight: '0.3rem'}}>
            {visibleItems.map((item, index) => (
                <div key={index} data-index={viewport.start + index}>
                    {children(item, viewport.start + index)}
                </div>
            ))}
        </div>
    );
};

const Detalhes = ({ Pule, Jogo, formatter, ModoExibicao }: Pick<Props, "Pule" | "Jogo" | "formatter" | "ModoExibicao">) => {
    const detalhe = useMemo(() => (
        <>
            {ModoExibicao !== 'ListaJogo' && <h6>{Jogo.NOME}</h6>}
            {ModoExibicao === 'ListaAposta' && <h6>{
                Jogo.STATUS === EStatus.Finalizado 
                    ? `Jogo finalizado em: ${formatter.setDate(new Date(Jogo.DT_FIM as string), { dateStyle: "long" }, false)}` 
                    : Jogo.STATUS === EStatus.Cancelado 
                        ? <Badge bg="danger">Cancelado</Badge>
                        : <Badge>Em Andamento</Badge>
            }</h6>}
            {ModoExibicao === 'Confirmacao' && <h6>{Pule.NOME}</h6>}
            {ModoExibicao !== 'ListaPremio' && `Data de Compra: ${formatter.setDate(new Date(Pule.DT_CADASTRO as string), { dateStyle: "long" }, false)}`}
            {ModoExibicao !== 'ListaPremio' &&
                <TabelaMais
                    colunas={[
                        { id: "valor", accessor: "Valor" },
                        { id: "quantidade", accessor: "Quantidade" },
                        { id: "total", accessor: "Total" }
                    ]}
                    dados={[{
                        Valor: `Valor: ${formatter.setMaskMoney(Jogo.VLR_APOSTA ?? 0)}`,
                        Quantidade: `Apostas: ${Pule.APOSTA?.length ?? 0}`,
                        Total: `Total: ${formatter.setMaskMoney((Jogo.VLR_APOSTA ?? 0 * (Pule.APOSTA?.length ?? 0)) ?? 0)}`
                    }]}
                    hideHeader
                />
            }
        </>
    ), [formatter, Pule, Jogo, ModoExibicao]);

    return detalhe;
}

const CartaoPule = ({ Pule, Jogo, Colors, MaxDigits, formatter, RepeteAposta, ModoExibicao = 'ListaJogo', Gerencial = false, ...rest }: Props) => {
    return (
        <Card className="m-3">
            <Card.Header className="d-flex w-100 justify-content-between">
                {ModoExibicao !== 'ListaPremio' ?
                    <>
                        <div>
                            <FaRegCopy 
                                style={{opacity: 0.4, userSelect: 'none', cursor: 'pointer'}} 
                                onClick={() => {
                                    Pule.CD_JOGO_PULE && navigator.clipboard.writeText(Pule.CD_JOGO_PULE.toString());
                                    toast('Número da pule copiada para área de transferência')
                                }}
                            /> {`Pule #${Pule.CD_JOGO_PULE}`}
                        </div>
                        <Badge
                            className="d-flex align-items-center"
                            pill
                            bg={(Pule.STATUS as EPuleStatus) === EPuleStatus.Finalizado ? 'success' : 'secondary'}
                        >
                            {(Pule.STATUS as EPuleStatus) === EPuleStatus.Finalizado ? 'Confirmado' : 'Pendente'}
                        </Badge>
                    </>
                :
                    <div>
                        {`Prêmio: ${Pule.NOME}`}
                    </div>
                }                
            </Card.Header>
            <Card.Body>
                <Detalhes Jogo={Jogo} Pule={Pule} formatter={formatter} ModoExibicao={ModoExibicao} />
                {
                    ModoExibicao === "Confirmacao" ?
                        <CartaoPuleConfirmacao
                            Pule={Pule}
                            Jogo={Jogo}
                            formatter={formatter}
                            MaxDigits={MaxDigits}
                        />
                    :
                        <DynamicList items={Pule.APOSTA}>
                            {(aposta: IAposta) => (
                                <CartaoPuleAposta
                                    Aposta={aposta}
                                    Colors={Colors}
                                    MaxDigits={MaxDigits}
                                    Detalhado={ModoExibicao === 'ListaPremio'}
                                    Gerencial={Gerencial}
                                />
                            )}
                        </DynamicList>
                }                
            </Card.Body>
            <Card.Footer>
                {ModoExibicao !== 'ListaPremio' ?
                    <div className="w-100 d-flex justify-content-center">
                        <div className="w-100 d-flex justify-content-center">
                            <Button 
                                variant="warning" 
                                size="sm" 
                                onClick={() => 
                                    PDFComprovantePule({Jogo: Jogo, Pule: Pule, MaxDigits: MaxDigits, formatter: formatter, url: window.location.origin})
                                }
                            ><FaReceipt /> Imprimir</Button>
                        </div>
                        {
                            ModoExibicao === 'ListaAposta' && <div className="w-100 d-flex justify-content-center">
                                <Button 
                                    variant="primary" 
                                    size="sm" 
                                    onClick={() => {
                                        RepeteAposta?.setSelecionadaTipoJogo(Pule.JOGO!.CD_TIPO_JOGO);
                                        RepeteAposta?.setSelecionada(transformarApostasEmNumeros(Pule.APOSTA!));
                                        RepeteAposta?.setShowModal(true);
                                    }}
                                ><FaArrowRotateRight /> Repetir aposta</Button>
                            </div>
                        }
                    </div>
                :
                    <div>{Pule.APOSTA?.length} Aposta(s) premiada(s)</div>
                }
            </Card.Footer>
        </Card>
    );
}

export default CartaoPule;
